import React from 'react';
import {motion} from 'framer-motion';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';

const Text = ({
  value,
  style,
  className,
  truncate,
  lines,
  fieldErr,
  animated,
  initial,
  animate,
  exit,
}) => {
  if (truncate) {
    return (
      <Truncate
        lines={lines}
        // className={`text-base md:text-lg text-white ${className}`}
        className={`text-base text-white ${className}`}
        style={{...style}}
      >
        {value}
      </Truncate>
    );
  }

  const Wrapper = (props) => {
    if (animated) {
      const additionalProps = {
        initial,
        animate,
        exit,
      };
      return <motion.p {...props} {...additionalProps} />;
    }
    return <p {...props} />;
  };

  return (
    <Wrapper
      className={`${
        fieldErr
          ? // ? 'text-sm md:text-base text-red-300'
            'text-sm text-red-300'
          : // : 'text-base md:text-lg text-white'
            'text-base text-white'
      } ${className}`}
      style={{...style}}
    >
      {value}
    </Wrapper>
  );
};

export default Text;

Text.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
  className: PropTypes.string,
  truncate: PropTypes.bool,
  lines: PropTypes.number,
  fieldErr: PropTypes.bool,
  animated: PropTypes.bool,
  initial: PropTypes.object,
  animate: PropTypes.object,
  exit: PropTypes.object,
};

Text.defaultProps = {
  value: '',
  style: {},
  className: '',
  truncate: false,
  lines: 4,
  fieldErr: false,
  animated: false,
  initial: {},
  animate: {},
  exit: {},
};
