import React from 'react';
import PropTypes from 'prop-types';
import {motion} from 'framer-motion';

const variants1 = {
  open: {
    rotate: '45deg',
    y: 7,
  },
  close: {},
};

const variants2 = {
  open: {
    x: -20,
    opacity: 0,
  },
  close: {},
};

const variants3 = {
  open: {
    rotate: '-45deg',
    y: -7,
  },
  close: {},
};

const Hamburger = ({open, onClick}) => {
  return (
    <div
      className={`cursor-pointer visible lg:invisible select-none tap-highlight-none self-center z-50`}
      style={{zIndex: 60}}
      role="button"
      onClick={onClick}
      onKeyPress={onClick}
      tabIndex={0}
    >
      <motion.div
        style={{zIndex: 60}}
        className={'w-hamburger h-px bg-primary cursor-pointer'}
        animate={open ? 'open' : 'close'}
        variants={variants1}
      />
      <motion.div
        className={'w-hamburger h-px bg-primary mt-hamburger cursor-pointer'}
        style={{marginTop: '6px', zIndex: 60}}
        animate={open ? 'open' : 'close'}
        variants={variants2}
      />
      <motion.div
        className={'w-hamburger h-px bg-primary mt-hamburger cursor-pointer'}
        style={{marginTop: '6px', zIndex: 60}}
        animate={open ? 'open' : 'close'}
        variants={variants3}
      />
    </div>
  );
};

export default Hamburger;

Hamburger.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func,
};
Hamburger.defaultProps = {
  open: false,
  onClick: () => {},
};
