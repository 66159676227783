import React from 'react';
import PropTypes from 'prop-types';

const Image = ({
  src,
  alt,
  className,
  mode,
  imgStyle,
  outterBoxStyle,
  pointer,
}) => {
  return (
    <div
      className={`overflow-hidden object-center object-cover ${className}`}
      style={outterBoxStyle}
    >
      <img
        className={`w-full h-full ${pointer ? 'cursor-pointer' : ''}`}
        src={src}
        alt={alt}
        style={{objectFit: mode, ...imgStyle}}
      />
    </div>
  );
};

export default Image;

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  mode: PropTypes.string,
  imgStyle: PropTypes.object,
  pointer: PropTypes.bool,
};

Image.defaultProps = {
  alt: 'image',
  className: null,
  mode: 'cover',
  imgStyle: {},
  pointer: false,
};
