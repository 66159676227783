import _ from 'lodash';

export default class Utils {
  static get breakpointSize() {
    return Object.freeze({
      xs: 426,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
      '2xl': 1536,
    });
  }

  static getCurrentBreakpoint() {
    if (!window) return '';
    const innerW = window.innerWidth;
    if (innerW < this.breakpointSize.xs) return '2xs';
    if (innerW < this.breakpointSize.sm) return 'xs';
    if (innerW < this.breakpointSize.md) return 'sm';
    if (innerW < this.breakpointSize.lg) return 'md';
    if (innerW < this.breakpointSize.xl) return 'lg';
    if (innerW < this.breakpointSize['2xl']) return 'xl';
    return '2xl';
  }

  static getSizeOfCurrentBreakpoint(bp) {
    return this.breakpointSize[bp];
  }

  // static navItem = [
  //   {itemName: 'Market', path: '/Market'},
  //   {itemName: 'About', path: '/#about-us'},
  //   {itemName: 'Our Works', path: '/#our-works'},
  //   {itemName: 'Contact Us', path: '/Contact'},
  // ];

  static getNavItem(t) {
    return [
      {itemName: t('navigator.market'), path: '/Market'},
      {itemName: t('navigator.about'), path: '/#aboutUs'},
      {itemName: t('navigator.our-works'), path: '/#ourWorks'},
      {itemName: t('navigator.contact-us'), path: '/Contact'},
    ];
  }

  static get theme() {
    return Object.freeze({
      color: {
        primary: '#B9AA87',
      },
      dimention: {
        sideNav: {
          height: '100vh',
          width: '280px',
        },
        navbar: {
          height: '8vh',
          width: '100%',
        },
      },
    });
  }

  static getAllContentfulCarResult(data) {
    return _.get(data, 'allContentfulCar.edges', []);
  }

  static parseOptionsToContentfulImageAPIQuery(options) {
    let i = 0;
    return _.reduce(
      options,
      (s, v, l) => {
        return s + `${i++ === 0 ? '?' : '&'}${l}=${v}`;
      },
      ''
    );
  }

  /**
   * Options please see: https://www.contentful.com/developers/docs/references/images-api/#/reference
   * @param {string} imageUrl
   * @param {object} options
   * @returns
   */
  static getContentfulImage(imageUrl, options) {
    const query = this.parseOptionsToContentfulImageAPIQuery(options);
    return imageUrl + query;
  }

  static getCImageUrl(image) {
    return _.get(image, 'url', null);
  }
  /**
   *
   * @param {Object} item
   * @param {String} placeholder
   * @returns {Object}
   */
  static getGatsbyImageData(item, placeholder) {
    return _.get(
      item,
      `${placeholder ? placeholder : 'file'}.childImageSharp.gatsbyImageData`,
      {}
    );
  }

  static isBrowser() {
    return typeof window !== 'undefined';
  }
}
