import React from 'react';
import Text from '../Typograhpy/Text';
import ContentContainer from '../ContentContainer';

import Image from '../Image';
// import logo from '../../assets/images/logo.png';
import fb from '../../assets/images/facebook.svg';
import ig from '../../assets/images/instagram.svg';

const Footer = () => {
  const containerClassName = 'text-white mt-4 text-center lg:text-left';
  return (
    <div className={`bg-black`}>
      <div className={`bg-grey`}>
        <ContentContainer>
          <div
            className={`
            grid grid-flow-col gap-4 grid-rows-5 grid-cols-1 py-12
            md:grid-cols-2 md:grid-rows-2
            lg:grid-cols-4 lg:grid-rows-1 
          `}
          >
            {/* <Image
              className={`w-44 h-40 object-cover mx-auto`}
              src={logo}
              alt={'logo'}
            /> */}
            <div className={containerClassName}>
              <Text value={'Tel: 2751 6101'} />
              <Text value={'info@mediasavvy.com.hk'} />
              <div className={`mt-2`}>
                <Text value={'Don’t miss any news from us:'} />
                <div className={`flex justify-center mt-2 lg:justify-start`}>
                  <Image
                    className={`w-10 h-10 mr-2`}
                    src={ig}
                    imgStyle={{fill: '#ffffff'}}
                  />
                  <Image
                    className={`w-10 h-10`}
                    src={fb}
                    imgStyle={{fill: '#ffffff'}}
                  />
                </div>
              </div>
            </div>
            <div className={containerClassName}>
              <Text value={'Office:'} />
              <Text value={'A5, 9/F Jumbo Building,'} />
              <Text value={'189 Wai Yip Street,'} />
              <Text value={'Kwun Tong, Kowloon,'} />
              <Text value={'Hong Kong'} />
            </div>
            <div className={containerClassName}>
              <Text value={'Shop:'} />
              <Text value={'G/F Jumbo Industrial Building,'} />
              <Text value={'189 Wai Yip Street,'} />
              <Text value={'Kwun Tong, Kowloon,'} />
              <Text value={'Hong Kong'} />
            </div>
            <div className={containerClassName}>
              <Text value={'Storage:'} />
              <Text value={'DD127, Lot 148,'} />
              <Text value={'Kam Tin Road, Shek Kong,'} />
              <Text value={'Yuen Long, N.T.,'} />
              <Text value={'Hong Kong'} />
            </div>
          </div>
        </ContentContainer>
      </div>
      <ContentContainer>
        <Text
          className={`py-5 text-center lg:text-left`}
          value={`© ${new Date().getFullYear()} Auto Savvy Limited.`}
        />
      </ContentContainer>
    </div>
  );
};

export default Footer;
