// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auto-parts-index-js": () => import("./../../../src/pages/AutoParts/index.js" /* webpackChunkName: "component---src-pages-auto-parts-index-js" */),
  "component---src-pages-car-[cid]-js": () => import("./../../../src/pages/car/[cid].js" /* webpackChunkName: "component---src-pages-car-[cid]-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/Contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-about-js": () => import("./../../../src/pages/Landing/About.js" /* webpackChunkName: "component---src-pages-landing-about-js" */),
  "component---src-pages-landing-contact-js": () => import("./../../../src/pages/Landing/Contact.js" /* webpackChunkName: "component---src-pages-landing-contact-js" */),
  "component---src-pages-landing-hero-js": () => import("./../../../src/pages/Landing/Hero.js" /* webpackChunkName: "component---src-pages-landing-hero-js" */),
  "component---src-pages-landing-index-js": () => import("./../../../src/pages/Landing/index.js" /* webpackChunkName: "component---src-pages-landing-index-js" */),
  "component---src-pages-landing-our-works-js": () => import("./../../../src/pages/Landing/OurWorks.js" /* webpackChunkName: "component---src-pages-landing-our-works-js" */),
  "component---src-pages-landing-sevice-js": () => import("./../../../src/pages/Landing/Sevice.js" /* webpackChunkName: "component---src-pages-landing-sevice-js" */),
  "component---src-pages-market-index-js": () => import("./../../../src/pages/Market/index.js" /* webpackChunkName: "component---src-pages-market-index-js" */)
}

