import React, {useState, useEffect, useMemo} from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import PropTypes from 'prop-types';
import SideNav from '../components/Navigator/SideNav';
import Navigator from '../components/Navigator/Navigator';
import Footer from '../components/Navigator/Footer';
import Util from '../util/util';

const overlayVariants = {
  open: {
    opacity: 0.7,
    transition: {
      duration: 0.23,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
};

const Layout = ({children}) => {
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const _onCloseSideNav = () => {
    setSideNavOpen(!sideNavOpen);
    const $body = document.querySelector('body');
    $body.style.overflow = sideNavOpen ? 'auto' : 'hidden';
  };

  const handleResize = () => {
    if (window.innerWidth < Util.breakpointSize.lg && sideNavOpen) {
      setSideNavOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const memorizedChildren = useMemo(() => children, [children]);

  return (
    <main className={`overflow-hidden`}>
      <Navigator
        isSideNavOpen={sideNavOpen}
        onClickHamburger={_onCloseSideNav}
      />
      <div>
        {memorizedChildren}
        <Footer />
        <AnimatePresence>
          {sideNavOpen && (
            <motion.div
              className={`bg-black fixed z-30 top-0 left-0 bottom-0 right-0 outline-none`}
              onClick={_onCloseSideNav}
              onKeyPress={_onCloseSideNav}
              tabIndex={0}
              role="button"
              variants={overlayVariants}
              animate={sideNavOpen ? 'open' : 'hidden'}
              exit={'hidden'}
              initial={'hidden'}
            />
          )}
        </AnimatePresence>
      </div>
      <SideNav open={sideNavOpen} onClickSideNav={_onCloseSideNav} />
    </main>
  );
};

export default Layout;

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};
Layout.defaultProps = {};
