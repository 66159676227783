export default class Enum {
  static get DISPATCH_ACTION() {
    return Object.freeze({
      APP: {
        SET: 'DISPATCH_ACTION.APP.SET',
        EXTEND: 'DISPATCH_ACTION.APP.EXTEND',
      },
    });
  }

  static get STATE() {
    return Object.freeze({
      APP: {
        IS_NAV_ANIMATED_ONCE: 'isAppAnimatedOnce',
        IS_WINDOW_SCROLLED: 'isWindowScrolled',
      },
    });
  }
}
