import React, {useMemo, useCallback} from 'react';
import {navigate} from 'gatsby';
import {useTranslation} from 'react-i18next';
import {motion} from 'framer-motion';
import PropTypes from 'prop-types';
import Utils from '../../util/util';
// import * as Styles from './sideNav.module.scss';

const variants = {
  open: {
    x: 0,
    transition: {
      type: 'spring',
      duration: 0.23,
      staggerChildren: 0.07,
      delayChildren: 0.23,
    },
  },
  hidden: {
    x: `-${Utils.theme.dimention.sideNav.width}`,
    transition: {
      type: 'spring',
      duration: 0.2,
      staggerChildren: 0.05,
      staggerDirection: -1,
      delay: 0.2,
    },
  },
};

const SideNavitgator = ({open = false, onClickSideNav = () => {}}) => {
  const {t} = useTranslation();
  const navItems = Utils.getNavItem(t);
  // const navSize = _.size(navItems);

  const navItemsVariants = {
    open: {
      opacity: 1,
      x: 0,
      transition: {
        stiffness: 50,
      },
    },
    hidden: {
      opacity: 0,
      x: -130,
    },
  };

  const _handleClickItem = useCallback(
    (item) => {
      onClickSideNav();
      navigate(item.path);
    },
    [onClickSideNav]
  );

  return useMemo(
    () => (
      <motion.div
        className={`fixed top-0 left-0 h-full bg-black py-24 px-3 transition-all z-40`}
        style={{width: Utils.theme.dimention.sideNav.width}}
        variants={variants}
        animate={open ? 'open' : 'hidden'}
        initial={'hidden'}
      >
        {navItems.map((i, idx) => (
          <div
            className={`text-white text-center font-bold mb-4 select-none tap-highlight-none transition-all focus:text-primary active:text-primary hover:text-primary`}
            key={i.itemName}
            role="button"
            tabIndex={0}
            onClick={() => _handleClickItem(i)}
            onKeyPress={() => _handleClickItem(i)}
          >
            <motion.li
              className={`list-none cursor-pointer`}
              variants={navItemsVariants}
            >
              {i.itemName}
            </motion.li>
          </div>
        ))}
      </motion.div>
    ),
    [
      _handleClickItem,
      open,
      navItems,
      Utils.theme.dimention.sideNav.width,
      navItemsVariants,
    ]
  );
};

export default SideNavitgator;

SideNavitgator.propTypes = {
  open: PropTypes.bool,
  onClickSideNav: PropTypes.func,
};
