import {
  ApolloClient as RawApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import fetch from 'isomorphic-fetch';
import configs from '../configs';

const headers = {
  authorization: `Bearer ${process.env.CONTENTFUL_ACCESS_TOKEN}`,
};

/**
 * Why fetch here.
 * https://stackoverflow.com/questions/64362315/gatsby-webpackerror-invariant-violation-error-with-apolloclient
 */
const link = createHttpLink({
  fetch,
  uri: configs.contentfulGrapServer,
  headers,
});

const client = new RawApolloClient({
  uri: configs.contentfulGrapServer,
  cache: new InMemoryCache(),
  headers,
  link,
});

export default class ApolloClient {
  static get Client() {
    return client;
  }

  static get Provider() {
    return ApolloProvider;
  }
}
