import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import {Link} from 'gatsby';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';
import {motion} from 'framer-motion';
import PropTypes from 'prop-types';
import ContentContainer from '../ContentContainer';
import Image from '../Image';
import Hamburger from '../Hamburger';
import AppService from '../../services/App';
import Enum from '../../util/enum.util';
import Utils from '../../util/util';
import imgLogo from '../../assets/images/logo.png';

const logoVariants = {
  show: {
    opacity: 1,
    x: 0,
  },
  hidden: {
    opacity: 0,
    x: 100,
  },
};
const logoDelay = 0.4;
const itemInitDely = 0.55;
const delayInterval = 0.12;
const menuItemVariants = (itemIdx, navSize) => ({
  show: {
    opacity: 1,
    x: 0,
    transition: {
      delay: itemInitDely + delayInterval * (navSize - itemIdx),
    },
  },
  hidden: {
    opacity: 0,
    x: -60,
  },
});
const bgVariant = {
  show: {
    opacity: 0.7,
  },
  hidden: {
    opacity: 0,
  },
};

const Navigator = ({onClickHamburger, isSideNavOpen}) => {
  const {t} = useTranslation();
  const navbar = Utils.getNavItem(t);

  const [state, dispatch] = useContext(AppService.ContextAPI.Context);
  const isNavAnimatedOnce = _.get(state, Enum.STATE.APP.IS_NAV_ANIMATED_ONCE);
  const isWindowScrolled = _.get(state, Enum.STATE.APP.IS_WINDOW_SCROLLED);

  const [windowWidth, setwindowWidth] = useState(0);
  const [showMenuContent, setshowMenuContent] = useState(false);

  const handleResize = useCallback(() => {
    setwindowWidth(window.innerWidth);
  }, [setwindowWidth]);

  useEffect(() => {
    setwindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    if (windowWidth !== 0 && !showMenuContent) setshowMenuContent(true);
  }, [windowWidth, showMenuContent]);

  useLayoutEffect(() => {
    if (showMenuContent && !isNavAnimatedOnce) {
      dispatch({
        type: Enum.DISPATCH_ACTION.APP.SET,
        payload: {
          key: Enum.STATE.APP.IS_NAV_ANIMATED_ONCE,
          value: true,
        },
      });
    }
  }, [showMenuContent, isNavAnimatedOnce, dispatch]);

  const handleScroll = useCallback(
    _.debounce(
      () => {
        if (window.scrollY > 0) {
          dispatch({
            type: Enum.DISPATCH_ACTION.APP.SET,
            payload: {
              key: Enum.STATE.APP.IS_WINDOW_SCROLLED,
              value: true,
            },
          });
        } else {
          dispatch({
            type: Enum.DISPATCH_ACTION.APP.SET,
            payload: {
              key: Enum.STATE.APP.IS_WINDOW_SCROLLED,
              value: false,
            },
          });
        }
      },
      500,
      {leading: true, trailing: true}
    ),
    [dispatch]
  );

  useEffect(() => {
    const scrollEvent = window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', scrollEvent);
    };
  }, []);

  const _renderLargeContent = useMemo(() => {
    return (
      <>
        <Link to="/" className={'z-50'}>
          <motion.div
            variants={logoVariants}
            initial={isNavAnimatedOnce ? 'show' : 'hidden'}
            animate={showMenuContent ? 'show' : 'hidden'}
            transition={{
              delay: logoDelay,
              ease: 'easeOut',
            }}
          >
            <Image
              src={imgLogo}
              alt={'logo'}
              className={`h-nav-logo w-nav-logo`}
              pointer
            />
          </motion.div>
        </Link>
        <ul className={`z-50 flex ml-auto`}>
          {_.map(navbar, (i, idx) => (
            <motion.li
              className={`text-white text-b font-bold ml-8 lg:ml-12 tap-highlight-none z-40`}
              key={i.itemName}
              variants={menuItemVariants(idx, _.size(navbar))}
              initial={isNavAnimatedOnce ? 'show' : 'hidden'}
              animate={showMenuContent ? 'show' : 'hidden'}
              transition={{duration: 0.2}}
            >
              <Link className={'cursor-pointer'} to={i.path}>
                {i.itemName}
              </Link>
            </motion.li>
          ))}
        </ul>
      </>
    );
  }, [
    logoVariants,
    isNavAnimatedOnce,
    showMenuContent,
    logoDelay,
    imgLogo,
    navbar,
  ]);

  const _renderSmallContent = useMemo(() => {
    return (
      <>
        <div
          className={`w-full h-full top-0 left-0 absolute flex justify-center items-center z-40`}
        >
          <Link to="/" className={`z-50 cursor-pointer`}>
            <Image
              src={imgLogo}
              alt={'logo'}
              className={`h-nav-logo w-nav-logo z-50`}
              pointer
            />
          </Link>
        </div>
        <Hamburger open={isSideNavOpen} onClick={onClickHamburger} />
      </>
    );
  }, [imgLogo, isSideNavOpen, onClickHamburger]);

  const _renderContent = useMemo(() => {
    const isSmallOrMedium = windowWidth < Utils.breakpointSize.lg;
    return isSmallOrMedium ? _renderSmallContent : _renderLargeContent;
  }, [windowWidth, _renderSmallContent, _renderLargeContent]);

  return (
    <div className={`h-navbar w-full fixed z-50 min-h-navbar`}>
      <motion.div
        className={`absolute top-0 left-0 w-full h-full bg-black z-10 opacity-0 transition-all`}
        style={{
          backdropFilter: 'blur(5px)',
        }}
        variants={bgVariant}
        animate={isWindowScrolled ? 'show' : 'hidden'}
        // initial={'hidden'}
      />
      {showMenuContent && (
        <ContentContainer className={`z-50 h-full flex items-center`}>
          {_renderContent}
        </ContentContainer>
      )}
    </div>
  );
};

export default Navigator;

Navigator.propTypes = {
  onClickHamburger: PropTypes.func,
  isSideNavOpen: PropTypes.bool,
};

Navigator.defaultProps = {
  onClickHamburger: () => {},
  isSecureContext: false,
};
