import React, {createContext, useReducer} from 'react';
import Enum from '../../util/enum.util';

const Context = createContext();
const initState = {
  [Enum.STATE.APP.IS_NAV_ANIMATED_ONCE]: false,
  [Enum.STATE.APP.IS_WINDOW_SCROLLED]: false,
};

const Reducer = (state, action) => {
  switch (action.type) {
    case Enum.DISPATCH_ACTION.APP.SET:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case Enum.DISPATCH_ACTION.APP.EXTEND:
      return {
        ...state,
        ...action.state,
      };
    default:
      return state;
  }
};

const _provider = ({children}) => {
  const _reducer = useReducer(Reducer, initState);

  return <Context.Provider value={_reducer}>{children}</Context.Provider>;
};

export default class ContextAPI {
  static get Context() {
    return Context;
  }
  static get Provider() {
    return _provider;
  }
  static get Consumer() {
    return Context.Consumer;
  }
}
