const configs = {
  apiHost: 'http://localhost:3000/api',
  autoPartsDriveUrl: 'https://www.google.com',
  contentfulGrapServer: `https://graphql.contentful.com/content/v1/spaces/${process.env.CONTENTFUL_SPACE_ID}`,
  formMessageMaxCount: 2000,
  fNameMaxCount: 40,
  lNameMaxCount: 40,
  emailMaxCount: 60,
  pagination: {
    marketPlace: 8,
  },
};

export default configs;
