import React from 'react';
import ApolloClient from './src/services/ApolloClient';
import AppService from './src/services/App';
import Layout from './src/Layout/Layout';
import './src/styles/global.css';
import './src/i18n/configs';

const App = ({children}) => {
  return <Layout>{children}</Layout>;
};

export const wrapRootElement = ({element}) => {
  return (
    <ApolloClient.Provider client={ApolloClient.Client}>
      <AppService.ContextAPI.Provider>
        <App>{element}</App>
      </AppService.ContextAPI.Provider>
    </ApolloClient.Provider>
  );
};
